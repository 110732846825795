import { useMap, useMapEvents } from "react-leaflet";
export default function ChangeView({
  setZoomLevel,
  setCenterCoords,
  isWindy,
  center,
  zoom,
}) {
  const map = useMap();
  console.log(map);
  if (isWindy) {
    map.zoomControl.disable();
  }
  else{
    map.zoomControl.enable();
  }
  map.flyTo(center, zoom);
  return null;
}
