import { Offcanvas, Form, Row, Col, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import styles from "../styles/common/form.module.css";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApiClient } from "../helpers/apiClient.js";
import { ApiClientType } from "../models/apiClientType.js";
import dateFormat from "dateformat";
import ImageModal from "./modal.js";

export default function LandingCenterForm(props) {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [seasonDateRange, setSeasonDateRange] = useState([null, null]);
  const [banDateRange, setBanDateRange] = useState([null, null]);
  const [facilities, setFacilities] = useState([]);
  const [sector, setSector] = useState([]);
  const [landingCenterType, setLandingCenterType] = useState("");
  const [fishingGear, setFishingGear] = useState([]);
  const [numberMechanizedBoats, setNumberMechanizedBoats] = useState(0);
  const [numberMotorizedBoats, setNumberMotorizedBoats] = useState(0);
  const [fishingCategory, setFishingCategory] = useState([]);
  const [speciesName, setSpeciesName] = useState([]);
  const [majorBuyer, setMajorBuyer] = useState("");
  const [images, setImages] = useState([]);
  const [cooperatives, setCooperatives] = useState([
    { cooperative_type: "", cooperative_name: "" },
  ]);
  const [skuListLoading, setSkuListLoading] = useState(false);
  const [skuList, setSkuList] = useState([]);
  const [skuListSearch, setSkuListSearch] = useState("");
  let landingCenter = props.value.selectedLandingCenter;
  useEffect(() => {
    if (landingCenter != null) {
      if (landingCenter.Meta.Facilities.FacilitiesAvailable != null) {
        setFacilities(landingCenter.Meta.Facilities.FacilitiesAvailable);
      }
      if (landingCenter.Meta.Facilities.LandingCenterType != null) {
        setLandingCenterType(landingCenter.Meta.Facilities.LandingCenterType);
      }
      if (landingCenter.Meta.Facilities.FishCategory != null) {
        setFishingCategory(landingCenter.Meta.Facilities.FishCategory);
      }
      if (landingCenter.Meta.Resources.Sector != null) {
        setSector(landingCenter.Meta.Resources.Sector);
      }
      if (landingCenter.Meta.Resources.FishingGear != null) {
        setFishingGear(landingCenter.Meta.Resources.FishingGear);
      }
      setNumberMotorizedBoats(
        landingCenter.Meta.Resources.NumberMotorizedBoats
      );
      setNumberMechanizedBoats(
        landingCenter.Meta.Resources.NumberMechanizedBoats
      );
      if (landingCenter.Meta.Expertise.MajorSpecies != null) {
        setSpeciesName(landingCenter.Meta.Expertise.MajorSpecies);
      }
      setMajorBuyer(landingCenter.Meta.Expertise.MajorBuyer);
      setSeasonDateRange([
        new Date(landingCenter.Meta.Dates.SeasonStart),
        new Date(landingCenter.Meta.Dates.SeasonEnd),
      ]);
      setBanDateRange([
        new Date(landingCenter.Meta.Dates.BanStart),
        new Date(landingCenter.Meta.Dates.BanEnd),
      ]);
      if (landingCenter.Meta.Cooperatives != null) {
        setCooperatives(landingCenter.Meta.Cooperatives);
      } else {
        setCooperatives([{ cooperative_type: "", cooperative_name: "" }]);
      }
      if (landingCenter.Meta.Resources.Images != null) {
        setImages(landingCenter.Meta.Resources.Images);
      }
    }
  }, []);

  useEffect(() => {
    let getData;
    (async () => {
      setSkuListLoading(true);
      getData = setTimeout(async () => {
        let a = await ApiClient(
          ApiClientType.post,
          process.env.REACT_APP_CMS_BASE_URL,
          "",
          {
            filter: {
              name: `%${skuListSearch}%`,
            },
          }
        );
        setSkuListLoading(false);
        setSkuList(a.body);
      }, 2000);
    })();
    return () => clearTimeout(getData);
  }, [skuListSearch]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (numberMotorizedBoats == NaN) {
      setNumberMotorizedBoats(0);
    }
    if (numberMechanizedBoats == NaN) {
      setNumberMechanizedBoats(0);
    }
    let result = await ApiClient(
      ApiClientType.patch,
      process.env.REACT_APP_LANDING_CENTER_URL,
      `/${landingCenter.id}`,
      {
        sector: sector,
        fishing_gear: fishingGear,
        number_mechanized_boats: numberMechanizedBoats,
        number_motorized_boats: numberMotorizedBoats,
        images: images,
        facilities_available: facilities,
        landing_center_type: landingCenterType,
        fish_category: fishingCategory,
        major_species: speciesName,
        major_buyer: majorBuyer,
        season_open: dateFormat(seasonDateRange[0], "yyyy-mm-dd'T'HH:MM:ss'Z'"),
        season_end: dateFormat(seasonDateRange[1], "yyyy-mm-dd'T'HH:MM:ss'Z'"),
        ban_open: dateFormat(banDateRange[0], "yyyy-mm-dd'T'HH:MM:ss'Z'"),
        ban_end: dateFormat(banDateRange[1], "yyyy-mm-dd'T'HH:MM:ss'Z'"),
        cooperatives: cooperatives,
      }
    );
    if (result == 200) {
      props.value.setIsSideNavVisible(false);
    }
  };

  const onUploadImage = async (e) => {
    let imageObject = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_IMAGE_UPLOAD,
      "",
      {}
    );
    let response = await fetch(imageObject.uploadLink.url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
      },
      body: e.target.files[0],
    });
    if (response.status == 200) {
      setImages([...images, imageObject.url]);
    }
  };
  return (
    <div>
      <p id={styles.formLabel}>{landingCenter.name}</p>
      <hr />
      <ImageModal value={{ setShowModal, showModal, selectedImage }} />
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>
            Landing center & facilities
          </Form.Label>
          <Form.Group as={Col}>
            <Multiselect
              selectedValues={facilities?.map((item) => {
                return { name: item };
              })}
              options={[
                { name: "Icing", id: 0 },
                { name: "Packing", id: 1 },
                { name: "Parking", id: 2 },
                { name: "Labour", id: 3 },
                { name: "Fuel", id: 4 },
                { name: "Shed", id: 5 },
                { name: "Water Supply", id: 6 },
              ]}
              closeIcon="cancel"
              placeholder="Facilities"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                let arr = facilities;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setFacilities(arr);
              }}
              onSelect={(list, item) => {
                setFacilities([...facilities, item.name]);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              onChange={(e) => setLandingCenterType(e.target.value)}
              value={landingCenterType}
            >
              <option value="">Landing Center Type</option>
              <option value="Harbour">Harbour </option>
              <option value="Village">Village</option>
              <option value="Jetti">Jetti</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Multiselect
              selectedValues={fishingCategory?.map((item) => {
                return { name: item };
              })}
              options={[
                { name: "Pelagic", id: 0 },
                { name: "Demersal", id: 1 },
                { name: "Molluscs", id: 2 },
                { name: "Crustaceans", id: 3 },
              ]}
              closeIcon="cancel"
              placeholder="Fishing Categories"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                let arr = fishingCategory;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setFishingCategory(arr);
              }}
              onSelect={(list, item) => {
                setFishingCategory([...fishingCategory, item.name]);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Mechanization</Form.Label>
          <Form.Group as={Col}>
            <Multiselect
              selectedValues={sector?.map((item) => {
                return { name: item };
              })}
              options={[
                { name: "Motorised", id: 0 },
                { name: "Mechanised", id: 1 },
                { name: "Traditional", id: 2 },
              ]}
              closeIcon="cancel"
              placeholder="Sector"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                let arr = sector;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setSector(arr);
              }}
              onSelect={(list, item) => {
                setSector([...sector, item.name]);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Multiselect
              selectedValues={fishingGear?.map((item) => {
                return { name: item };
              })}
              options={[
                { name: "Ring Net", id: 0 },
                { name: "Hook Net", id: 1 },
                { name: "Gill Net", id: 2 },
                { name: "Bottom Trawling", id: 3 },
                { name: "Purse Siene", id: 4 },
                { name: "Dolnet", id: 5 },
                { name: "Hand Line", id: 6 },
                { name: "Hook and Line", id: 7 },
                { name: "Long Liner", id: 8 },
                { name: "Pole and line", id: 9 },
                { name: "Ring Seine", id: 10 },
                { name: "Shore Seinet", id: 11 },
                { name: "Squid jigging vessel", id: 12 },
                { name: "Trawler", id: 13 },
              ]}
              closeIcon="cancel"
              placeholder="Fishing Gear"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                let arr = fishingGear;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setFishingGear(arr);
              }}
              onSelect={(list, item) => {
                setFishingGear([...fishingGear, item.name]);
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>
              No. of Mechanized Boats
            </Form.Label>

            <Form.Control
              placeholder="No. of Mechanized Boats"
              type="number"
              min="0"
              value={numberMechanizedBoats}
              oninput="validate(this)"
              onChange={(e) => {
                setNumberMechanizedBoats(parseInt(e.target.value));
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>
              No. of Motorised Boats
            </Form.Label>

            <Form.Control
              placeholder="No. of Motorized Boats"
              type="number"
              oninput="validate(this)"
              min="0"
              value={numberMotorizedBoats}
              onChange={(e) =>
                setNumberMotorizedBoats(parseInt(e.target.value))
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>Fishing Expertise</Form.Label>
            <Multiselect
              selectedValues={speciesName?.map((item) => {
                return { displayName: item };
              })}
              options={skuList}
              closeIcon="cancel"
              placeholder="Select Species"
              id={styles.multiSelect}
              showArrow={true}
              loading={skuListLoading}
              closeOnSelect={false}
              displayValue="displayName"
              onRemove={(list, item) => {
                let arr = speciesName;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setSpeciesName(arr);
              }}
              onSearch={(e) => {
                setSkuListSearch(e);
              }}
              onSelect={(list, item) => {
                setSpeciesName([...speciesName, item.name]);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Major Buyers</Form.Label>
          <Form.Group as={Col}>
            {["Exporter", "Supplier", "Local Retailers"].map((type) => (
              <div
                key={type}
                style={{ display: "inline-block", marginRight: "50px" }}
              >
                <Form.Check
                  key={type}
                  type="radio"
                  name="majorBuyer"
                  label={`${type}`}
                  value={type}
                  onChange={(e) => setMajorBuyer(e.target.value)}
                  checked={type == majorBuyer ? true : false}
                />
              </div>
            ))}
          </Form.Group>
        </Row>
        <Form.Label id={styles.formLabel}>Fishermen Co-operatives</Form.Label>
        {cooperatives?.map((item, index) => {
          return (
            <Row key={index} className="mb-3">
              <Form.Group as={Col}>
                <Form.Select
                  onChange={(e) => {
                    cooperatives[index].cooperative_type = e.target.value;
                    setCooperatives([...cooperatives]);
                  }}
                  value={cooperatives[index].cooperative_type}
                >
                  <option value="">Type</option>
                  <option value="Society">Society</option>
                  <option value="Union">Union</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  placeholder="Name"
                  type="text"
                  value={cooperatives[index].cooperative_name}
                  onChange={(e) => {
                    cooperatives[index].cooperative_name = e.target.value;
                    setCooperatives([...cooperatives]);
                  }}
                />
              </Form.Group>
            </Row>
          );
        })}

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Button
              variant="success"
              id={styles.addItemButton}
              onClick={() =>
                setCooperatives([
                  ...cooperatives,
                  { cooperative_type: "", cooperative_name: "" },
                ])
              }
            >
              + Add More
            </Button>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>Fishing Season</Form.Label>
            <DatePicker
              id={styles.dateRange}
              selectsRange={true}
              isClearable={true}
              startDate={seasonDateRange[0]}
              endDate={seasonDateRange[1]}
              onChange={(e) => {
                setSeasonDateRange(e);
              }}
              placeholderText="dd/mm/yy-dd/mm/yy"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>Fishing Ban</Form.Label>
            <DatePicker
              id={styles.dateRange}
              selectsRange={true}
              isClearable={true}
              startDate={banDateRange[0]}
              endDate={banDateRange[1]}
              onChange={(e) => {
                setBanDateRange(e);
              }}
              placeholderText="dd/mm/yy-dd/mm/yy"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Landing center assets</Form.Label>
          <Form.Group as={Col}>
            <Form.Control type="file" onChange={(e) => onUploadImage(e)} />
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>
        <Row className="mb-3">
          {images?.map((item, index) => {
            return (
              <Col key={index}>
                <img
                  onClick={() => {
                    setSelectedImage(item);
                    setShowModal(true);
                  }}
                  src={item}
                  height="100"
                  width="100"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <div id={styles.buttonsContainer}>
              <Button variant="primary" type="submit" id={styles.saveButton}>
                Save Changes
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
